import React, { useState, useEffect } from 'react';
import {
  ChakraProvider,
  Box,
  ColorModeScript,
  keyframes,
  usePrefersReducedMotion,
  useColorMode,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Button,
  Text,
} from '@chakra-ui/react';
import Header from './components/Header';
import FileListMobile from './components/FileListMobile';
import theme from './theme';
import './App.css';
import CookieConsent from "react-cookie-consent"; 

function App() {
  const { colorMode } = useColorMode(); 
  const [isModalOpen, setIsModalOpen] = useState(false); // Estado para controlar el modal

  const prefersReducedMotion = usePrefersReducedMotion();

  const fadeIn = keyframes`
    from { opacity: 0; }
    to { opacity: 1; }
  `;

  const animation = prefersReducedMotion
    ? undefined
    : `${fadeIn} 0.5s ease-in-out`;

  return (
    <>
      <ColorModeScript initialColorMode={theme.config.initialColorMode} />
      <ChakraProvider theme={theme}>
        <Box overflowX="hidden" animation={animation}>
          {/* Header con el logo y el selector de modo */}
          <Header />

          {/* Mostramos siempre FileListMobile */}
          <FileListMobile />

          {/* Aviso de Cookies en el bottom */}
          <CookieConsent
            location="bottom"
            buttonText="Aceptar"
            style={{
              background: colorMode === 'dark' ? '#fff' : '#000',
              color: colorMode === 'dark' ? '#000' : '#fff',
              borderTop: `2px solid ${colorMode === 'dark' ? '#fff' : '#000'}`,
              padding: '15px',
            }}
            buttonStyle={{
              color: colorMode === 'dark' ? '#000' : '#fff',
              fontSize: "13px",
              backgroundColor: colorMode === 'dark' ? '#fff' : '#000',
              border: `1px solid ${colorMode === 'dark' ? '#000' : '#fff'}`,
            }}
            expires={365}
          >
            Este sitio web utiliza cookies para mejorar la experiencia del usuario.{" "}
            <Button 
              variant="link" 
              onClick={() => setIsModalOpen(true)} 
              color={colorMode === 'dark' ? '#000' : '#fff'}
              textDecoration="underline"
            >
              Más información
            </Button>
          </CookieConsent>

          {/* Modal para mostrar información de cookies */}
          <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} size="lg">
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Política de Cookies</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <Text fontSize="sm" mb={4}>
                  <strong>Actualizado:</strong> 20/9/2024
                </Text>
                <Text fontSize="md" mb={4}>
                  <strong>¿Qué son las cookies?</strong><br />
                  Las cookies y tecnologías similares son documentos de texto muy pequeños o fragmentos de código que a menudo contienen un código de identificación único.
                </Text>
                <Text fontSize="md" mb={4}>
                  <strong>¿Por qué utilizamos cookies?</strong><br />
                  Las cookies nos ayudan a mejorar y optimizar el sitio web, pudiendo medir el tráfico y las interacciones internas.
                </Text>
                <Text fontSize="md" mb={4}>
                  <strong>¿Qué tipo de cookies utilizamos?</strong><br />
                  <strong>Cookies necesarias:</strong> Estas cookies son necesarias para que el sitio web funcione correctamente.
                </Text>
                <Text fontSize="md" mb={4}>
                  <strong>Cookies funcionales:</strong> Estas cookies permiten una mayor funcionalidad para los visitantes de nuestro sitio web.
                </Text>
                <Text fontSize="md" mb={4}>
                  <strong>Sin clasificar:</strong> Estas cookies están todavía en proceso de clasificación.
                </Text>
                <Text fontSize="md" mb={4}>
                  <strong>¿Cómo puedo desactivar o eliminar las cookies?</strong><br />
                  Puedes optar por no utilizar todas las cookies, excepto las necesarias.
                </Text>
              </ModalBody>
            </ModalContent>
          </Modal>
        </Box>
      </ChakraProvider>
    </>
  );
}

export default App;
