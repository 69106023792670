import React from 'react';
import { Box, Text, useColorMode, Flex } from '@chakra-ui/react';

const FileListMobile = () => {
  const { colorMode } = useColorMode();

  return (
    <Box height="90vh" bg={colorMode === 'dark' ? '#000' : 'gray.100'}>
      {/* Mensaje centrado y justificado */}
      <Flex height="80vh" justify="center" align="center" textAlign="center">
        <Text
          fontSize="md"
         
          fontFamily="'Space Mono', monospace"
          textAlign="justify"
        >
          Este no es. <br /> Prueba a escanear otro.
        </Text>
      </Flex>
    </Box>
  );
};

export default FileListMobile;
