import { extendTheme } from "@chakra-ui/react";

const config = {
  initialColorMode: 'dark',
  useSystemColorMode: false,
};

const theme = extendTheme({
  config,
  styles: {
    global: (props) => ({
      "html, body": {
        bg: props.colorMode === "dark" ? "#000" : "#edf2f8",
        color: props.colorMode === "dark" ? "#edf2f8" : "#000",
        minHeight: "100vh",
        transition: "background-color 0.2s ease",
      },
    }),
  },
  components: {
    ListItem: {
      baseStyle: (props) => ({
        bg: props.colorMode === 'dark' ? '#000' : 'gray.100',
        color: props.colorMode === 'dark' ? 'whiteAlpha.900' : 'gray.800',
        _hover: {
          bg: props.colorMode === 'dark' ? '#202020' : 'gray.200',
          color: props.colorMode === 'dark' ? 'gold' : 'gray.800',
        },
      }),
    },
  },
});

export default theme;
